import { RobotsEnum } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { memo } from 'react'
import pkg from '../../package.json'

export type CrumbType = {
  href: Routes | null
  text: string
}

type PropsType = {
  readonly author?: string
  readonly canonicalUrl?: string
  readonly children?: React.ReactNode
  readonly crumbs?: ReadonlyArray<CrumbType>
  readonly description?: string | null
  readonly image?: string | null
  readonly publishDate?: string | null
  readonly referrer?: string | null
  readonly robots?: RobotsEnum
  readonly title?: string
}

/**
 * This component injects elements to the `<head>` of your page via `next/head`.
 * To avoid duplicated `tags` in `<head>` you can use the `key` property, which will make sure every tag is only rendered once.
 *
 * @see https://nextjs.org/docs/api-reference/next/head
 * @see https://htmlhead.dev/
 */
function Meta(props: PropsType) {
  const { asPath } = useRouter()
  const url = props?.canonicalUrl ?? pkg.homepage + asPath
  const title = props.title ?? 'Find Cannabis Industry Jobs & Apply Online'

  const crumbList = props.crumbs?.map((c, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: c.text,
      item: c?.href != null ? `https://app.vangst.com${c.href}` : null,
    }
  })

  // https://developers.google.com/search/docs/appearance/structured-data/breadcrumb
  const structuredCrumbData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: crumbList,
  }

  return (
    <Head key="head">
      <meta
        key="meta:viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
        name="viewport"
      />
      <title>{title}</title>
      <meta
        key="meta:description"
        name="description"
        content={props.description ?? pkg.description}
      />
      <meta key="meta:title" name="title" content={title} />
      <meta key="og:site_name" property="og:site_name" content="Vangst" />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:url" property="og:url" content={url} />
      <meta
        key="og:description"
        property="og:description"
        content={props.description ?? pkg.description}
      />
      <meta
        key="og:image"
        name="image"
        property="og:image"
        content={props.image ?? pkg.logo}
      />
      {props?.publishDate != null && (
        <meta
          key="meta:publish_date"
          name="publish_date"
          property="og:publish_date"
          content={props.publishDate}
        />
      )}
      {props?.author != null && (
        <meta key="meta:author" name="author" content={props.author} />
      )}
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta key="twitter:site" name="twitter:site" content="@vangsttalent" />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={props.image ?? pkg.logo}
      />
      <meta
        key="meta:robots"
        name="robots"
        content={
          props.robots === RobotsEnum.IndexFollow
            ? 'index,follow'
            : 'noindex,nofollow'
        }
      />
      <meta
        key="meta:referrer"
        name="referrer"
        content={props.referrer ?? 'always'}
      />
      {props.children}
      <link
        key="meta:canonical"
        rel="canonical"
        href={props.canonicalUrl ?? url}
      />
      {props.crumbs != null && props.crumbs.length > 0 && (
        <script
          key="meta:structured-crumb-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredCrumbData),
          }}
        />
      )}
    </Head>
  )
}

export default memo(Meta)
