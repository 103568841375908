// If adding a new icon, please also add it to the list in the
// assets.stories.tsx file

export type IconName =
  | 'grass' // the default
  | 'bars vertical'
  | 'business'
  | 'pencil balloon'
  | 'people'
  | 'pin drop'
  | 'psychology'
  | 'school'
  | 'search'
  | 'speech balloons'

type PropsType = React.SVGAttributes<SVGElement> & {
  readonly as?: IconName
  readonly size?: string | number
}

export default function MastIcon(props: PropsType) {
  const { as = 'grass', size = '2em' } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 99 99"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
        {as === 'grass' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 80.9082 2.13488 86.3177 5.6753 90.4993H29.1048C25.5157 76.6835 14.6498 65.8669 0.833984 62.2777C3.98065 61.491 7.27482 60.9993 10.6673 60.9993C31.953 60.9993 49.2764 77.8859 49.9785 99H59.7666C59.6879 96.1139 59.3556 93.2697 58.8015 90.4993C58.1132 86.9593 56.9332 83.5668 55.5073 80.3218C62.3907 68.7677 74.9282 60.9993 89.334 60.9993C92.6664 60.9993 95.9039 61.4738 99 62.2362V24C99 10.7452 88.2548 0 75 0H24ZM99 62.3215C85.2638 65.9543 74.471 76.7393 70.8473 90.4993H93.3247C96.8651 86.3177 99 80.9082 99 75V62.3215ZM88.0065 31.5977C78.8615 37.301 71.7323 45.9052 67.8973 56.181C60.8173 59.6227 54.6715 64.6869 50.0007 70.931V70.8327C50.0007 49.5435 66.914 32.286 88.0065 31.5977ZM23.9423 21.666C34.6607 26.0418 43.019 34.7444 47.149 45.5118C44.2482 50.4285 42.0848 55.886 41.0523 61.6877C39.1348 60.2127 37.1682 58.836 35.054 57.656C35.0733 57.2696 35.1078 56.8832 35.1426 56.4937C35.1963 55.8922 35.2507 55.2836 35.2507 54.6568C35.2507 42.2177 31.0223 30.811 23.9423 21.666Z" />
        )}
        {as === 'bars vertical' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.2548 10.7452 99 24 99H67.075V55.9583H77.4583V98.8756C89.5583 97.6445 99 87.4249 99 75V24C99 10.7452 88.2548 0 75 0H24ZM36.6667 39.0167H25.5417V82.7083H36.6667V39.0167ZM56.6917 20.2917H46.3083V82.7083H56.6917V20.2917Z" />
        )}
        {as === 'business' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.2548 10.7452 99 24 99H25V32H64.1667V47.6667H99V24C99 10.7452 88.2548 0 75 0H24ZM95.5 87.4866V55.5H64.1667V94.6667H88.7594C91.4705 92.7664 93.7706 90.3198 95.5 87.4866ZM32.8333 94.6667H56.3333V86.8333H32.8333V94.6667ZM32.8333 79H56.3333V71.1667H32.8333V79ZM32.8333 63.3333H56.3333V55.5H32.8333V63.3333ZM32.8333 47.6667H56.3333V39.8333H32.8333V47.6667ZM72 63.3333H87.6667V71.1667H72V63.3333ZM72 79H87.6667V86.8333H72V79Z" />
        )}
        {as === 'pencil balloon' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 87.8093 10.035 98.2748 22.6723 98.9639L22.6953 37.3327C22.6953 34.216 25.217 31.666 28.3336 31.666H73.667C76.7836 31.666 79.3336 34.216 79.3336 37.3327V71.3327C79.3336 74.4493 76.7836 76.9993 73.667 76.9993H34.0003L26.5581 99H75C88.2548 99 99 88.2548 99 75V24C99 10.7452 88.2548 0 75 0H24ZM34.0003 58.6677V65.666H40.9986L60.5203 46.201C61.087 45.6343 61.087 44.756 60.5203 44.1894L55.5053 39.1743C54.9386 38.6077 54.0603 38.6077 53.4936 39.1743L34.0003 58.6677ZM46.7503 65.666H68.0003V59.9993H52.417L46.7503 65.666Z" />
        )}
        {as === 'people' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 82.108 3.09001 88.4943 8 92.8888V87.5C8 75.85 31.35 70 43 70C46.3 70 50.5 70.5 54.85 71.4C44.65 77.05 43 84.5 43 88.75V99H53V88.75C53 79.6 71.35 75 80.5 75C85.3469 75 92.7755 76.2908 98.6904 78.8649C98.8941 77.6067 99 76.3157 99 75V24C99 10.7452 88.2548 0 75 0H24ZM92.95 52.5C92.95 59.4 87.4 65 80.5 65C73.6 65 68 59.4 68 52.5C68 45.6 73.6 40 80.5 40C87.4 40 92.95 45.6 92.95 52.5ZM57.95 45C57.95 53.3 51.3 60 43 60C34.7 60 28 53.3 28 45C28 36.7 34.7 30 43 30C51.3 30 57.95 36.7 57.95 45Z" />
        )}
        {as === 'pin drop' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.1714 10.6103 98.8646 23.75 98.9987V89H76.25V98.968C88.9238 98.3177 99 87.8356 99 75V24C99 10.7452 88.2548 0 75 0H24ZM50 21.5C62.4125 21.5 72.5 31.5875 72.5 44C72.5 60.875 50 85.25 50 85.25C50 85.25 27.5 60.875 27.5 44C27.5 31.5875 37.5875 21.5 50 21.5ZM50 36.5C45.875 36.5 42.5 39.875 42.5 44C42.5 48.125 45.875 51.5 50 51.5C54.1625 51.5 57.5 48.125 57.5 44C57.5 39.875 54.125 36.5 50 36.5Z" />
        )}
        {as === 'psychology' && (
          <path d="M0 24C0 10.7452 10.7452 0 24 0H75C88.2548 0 99 10.7452 99 24V75C99 88.2548 88.2548 99 75 99H68.0002V83.44C78.6202 78.4 86.0002 67.555 86.0002 55C86.0002 37.585 71.9152 23.5 54.5002 23.5C37.6252 23.5 23.9002 36.73 23.0902 53.38L14.4502 64.9C13.3252 66.385 14.4052 68.5 16.2502 68.5H23.0002V82C23.0002 86.95 27.0502 91 32.0002 91H36.5002V99H24C10.7452 99 0 88.2548 0 75V24ZM54.4995 48.5645C50.9445 48.5645 48.0645 51.4445 48.0645 54.9995C48.0645 58.5545 50.9445 61.4345 54.4995 61.4345C58.0545 61.4345 60.9345 58.5545 60.9345 54.9995C60.9345 51.4445 58.0545 48.5645 54.4995 48.5645ZM67.9102 56.755C67.9552 56.17 68.0002 55.585 68.0002 55C68.0002 54.37 67.9552 53.785 67.8202 53.245L71.6452 50.275C72.0052 50.005 72.0952 49.51 71.8702 49.105L68.2702 42.895C68.0902 42.49 67.5952 42.355 67.1902 42.49L62.6902 44.29C61.7452 43.57 60.7552 42.985 59.6752 42.535L59.0002 37.765C58.9102 37.315 58.5502 37 58.1002 37H50.9002C50.4502 37 50.0902 37.315 50.0002 37.765L49.3252 42.535C48.2452 42.985 47.2102 43.615 46.3102 44.29L41.8102 42.49C41.4052 42.355 40.9552 42.49 40.7302 42.895L37.1302 49.105C36.9052 49.555 36.9952 50.005 37.3552 50.275L41.1802 53.245C41.0902 53.785 41.0002 54.415 41.0002 55C41.0002 55.585 41.0452 56.1699 41.0902 56.7549L41.0902 56.755L37.3102 59.725C36.9502 59.995 36.8602 60.49 37.0852 60.85L40.6852 67.105C40.9102 67.51 41.4052 67.645 41.8102 67.51L46.2652 65.71C47.2102 66.43 48.2002 67.015 49.3252 67.465L50.0002 72.235C50.0902 72.685 50.4502 73 50.9002 73H58.1002C58.5502 73 58.9552 72.685 59.0002 72.235L59.7202 67.465C60.8002 67.015 61.7902 66.43 62.7352 65.71L67.1902 67.51C67.5502 67.645 68.0452 67.51 68.2702 67.105L71.8702 60.85C72.0952 60.445 72.0052 59.995 71.6452 59.725L67.9102 56.755Z" />
        )}
        {as === 'school' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.2548 10.7452 99 24 99H75C76.5823 99 78.1289 98.8469 79.6256 98.5546V45.8731L48.5006 62.8535L10.459 42.1035L48.5006 21.3535L86.5423 42.1035V96.0473C93.9676 91.9667 99 84.071 99 75V24C99 10.7452 88.2548 0 75 0H24ZM24.2923 78.0492V56.5593L48.5006 69.7702L72.709 56.5593V78.0492L48.5006 91.2601L24.2923 78.0492Z" />
        )}
        {as === 'search' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.2548 10.7452 99 24 99H75C80.121 99 84.8674 97.3961 88.7642 94.6633L69.5417 75.4792V72.3521L68.4729 71.2438C63.9604 75.1229 58.1021 77.4583 51.7292 77.4583C37.5187 77.4583 26 65.9396 26 51.7292C26 37.5187 37.5187 26 51.7292 26C65.9396 26 77.4583 37.5187 77.4583 51.7292C77.4583 58.1021 75.1229 63.9604 71.2438 68.4729L72.3521 69.5417H75.4792L94.6633 88.7642C97.3961 84.8674 99 80.121 99 75V24C99 10.7452 88.2548 0 75 0H24ZM33.9167 51.7292C33.9167 61.5854 41.8729 69.5417 51.7292 69.5417C61.5854 69.5417 69.5417 61.5854 69.5417 51.7292C69.5417 41.8729 61.5854 33.9167 51.7292 33.9167C41.8729 33.9167 33.9167 41.8729 33.9167 51.7292Z" />
        )}
        {as === 'speech balloons' && (
          <path d="M24 0C10.7452 0 0 10.7452 0 24V75C0 88.2548 10.7452 99 24 99H75C76.6915 99 78.3422 98.825 79.9348 98.4921L70.3333 77.3333H33.6667C31.8333 77.3333 30.3333 75.8333 30.3333 74V67.3333H73.6667V37.3333H80.3333C82.1667 37.3333 83.6667 38.8333 83.6667 40.6667V97.3875C92.6383 93.9119 99 85.1989 99 75V24C99 10.7452 88.2548 0 75 0H24ZM67 27.3333V57.3333C67 59.1667 65.5 60.6667 63.6667 60.6667H30.3333L17 74V27.3333C17 25.5 18.5 24 20.3333 24H63.6667C65.5 24 67 25.5 67 27.3333Z" />
        )}
      </g>
    </svg>
  )
}
