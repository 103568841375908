import Routes from '@vangst/services/routes'
import { clsx } from 'clsx'
import ListPseudos from '../views/ListPseudos'
import ClickyLink from './ClickyLink'

export type CrumbType = {
  href: Routes | null
  text: string
}

type PropsType = React.HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode
  crumbs: CrumbType[]
}

export default function Breadcrumbs(props: PropsType) {
  const { children, className, crumbs, ...rest } = props

  return (
    <>
      <nav
        aria-label="Breadcrumb"
        className={clsx(
          'contain-xc relative flex h-12 items-center justify-start overflow-x-auto',
          className,
        )}
        {...rest}
      >
        <ListPseudos as="/" at="after" className="flex text-sm text-grey-dark">
          {crumbs?.map((crumb) => (
            <li key={`crumb-${crumb.text}`}>
              {crumb?.href ? (
                <ClickyLink
                  href={crumb.href}
                  className="max-w-16 truncate text-sm text-grey hocus:text-orange sm:max-w-48"
                >
                  {crumb.text}
                </ClickyLink>
              ) : (
                <ClickyLink
                  href=""
                  aria-current="page"
                  className="pointer-events-none max-w-18 overflow-x-clip truncate text-sm sm:max-w-48"
                >
                  {crumb.text}
                </ClickyLink>
              )}
            </li>
          ))}
        </ListPseudos>
        {children}
      </nav>
    </>
  )
}
