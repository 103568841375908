import clsx from '@vangst/lib/clsx'
import Image from 'next/image'
import MastIcon, { IconName } from '../assets/MastIcon'
import Markdown from './Markdown'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  accent?: string
  description?: string
  icon?: IconName
  imgAlt?: string
  imgSrc?: string
  imgWidth?: number
  imgHeight?: number
  markdownContent?: string
  subtitle?: string
  subtitleLink?: string
  title: string
}

/**
 * Render a page header with a title, optional subtitle and optional children.
 *
 * @example
 * <PageHeader
 *   title="The Default Header"
 *   subtitle="A subtitle is an optional one or two sentence blurb."
 * />
 *
 * <PageHeader
 *   className="mood-orange"
 *   title="Cannabis careers grow here."
 *   subtitle="You can pass childrent to the PageHeader component."
 * >
 *   <p>Children are optional.</p>
 * </PageHeader>
 */
function PageHeader(props: PropsType) {
  const {
    children,
    title,
    subtitle,
    subtitleLink,
    description,
    markdownContent,
    ...more
  } = props
  const { accent, className, icon, ...img } = more
  const { imgAlt, imgSrc, imgWidth, imgHeight /*...rest*/ } = img
  const mood = className?.match(/mood-\w+(?:-\w+)?/g)?.[0] ?? null
  const isMoodWhite = !mood || mood === 'mood-white'

  return (
    <header
      className={clsx(
        !mood && 'mood-white',
        className,
        imgSrc && 'md:mood-black',
      )}
    >
      <div className="contain-xyc relative flex flex-col justify-center gap-4 md:min-h-[26rem]">
        <div className="contain-y z-10 flex flex-col gap-2 md:flex-row md:gap-8">
          <div className="flex max-w-5xl flex-col gap-4">
            <h1 className="break-words heading-xl">{title}</h1>
            {subtitleLink ? (
              <a href={subtitleLink} className="ml-2 break-words sm:text-lg">
                {subtitle}
              </a>
            ) : (
              <p className="ml-2 break-words sm:text-lg">{subtitle}</p>
            )}
            {description && (
              <div className="ml-2 mt-4 break-words text-sm sm:text-md">
                <Markdown renderType="basic">{description}</Markdown>
              </div>
            )}
            {markdownContent && (
              <section className="contain-xyc mood-white">
                <div className="text-sm">
                  <Markdown renderType="basic">{markdownContent}</Markdown>
                </div>
              </section>
            )}
          </div>
          <div
            className={clsx(
              'order-first flex shrink-0 text-[4rem] sm:text-[6rem]',
              !accent && isMoodWhite && 'text-orange',
              accent,
              imgSrc && 'md:text-white',
            )}
            role="presentation"
          >
            <MastIcon as={icon} size="1em" />
          </div>
        </div>
        {imgAlt && imgSrc && (
          <Image
            alt={imgAlt}
            className="hidden h-auto max-w-full object-cover md:block"
            fill={!imgWidth && !imgHeight ? true : undefined}
            width={imgWidth}
            height={imgHeight}
            sizes="(max-width: 641px) 0vw, (max-width: 1441px) 86em, 100vw"
            src={imgSrc}
            priority
          />
        )}
        {children}
      </div>
    </header>
  )
}

export default PageHeader
